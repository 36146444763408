import { APP_INITIALIZER, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AppLayoutModule } from "./layout/app.layout.module";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { initializeTheme } from "./app.theme.initializer";
import { ThemeService } from "./core/services/theme.service";
import { ToastModule } from "primeng/toast";
import { MessageService } from "primeng/api";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { TenantIdInterceptor } from "./core/interceptors/tenant-id.interceptor";
import { DialogService } from "primeng/dynamicdialog";
import { AuthorizationInterceptor } from "./core/interceptors/authorization.interceptor";
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AppLayoutModule,
    ConfirmDialogModule,
    ToastModule,
  ],
  providers: [
    MessageService,
    DialogService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeTheme,
      deps: [ThemeService],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: TenantIdInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
