<div
  class="layout-sidebar"
  (mouseenter)="onMouseEnter()"
  (mouseleave)="onMouseLeave()"
>
  <div class="sidebar-header h-5rem">
    <a [routerLink]="['/']" class="logo">
      <div class="logo-image w-full flex justify-content-center">
        <img
          [src]="theme.configValue.mainLogoContrastUrl"
          class="tactiq-logo"
          alt="Tactiq logo"
        />
      </div>
    </a>
  </div>

  <twx-retailer-sidebar-card></twx-retailer-sidebar-card>

  <div #menuContainer class="layout-menu-container">
    <twx-menu></twx-menu>
  </div>

  <div class="sidebar-footer">
    <img [src]="'assets/logos/tactiq-patent-pending.svg'" alt="Tactiq logo" />
    <p>Patent Pending</p>
    <button
      pButton
      label="Sign Out"
      icon="pi pi-sign-out"
      type="button"
      class="form-m-w p-button-white mt-3"
      (click)="logout()"
    ></button>
  </div>
</div>
