<div class="layout-wrapper" [ngClass]="containerClass" [class]="sidebarClass">
  <div class="layout-content-wrapper">
    <twx-topbar></twx-topbar>
    <div class="layout-content">
      <twx-breadcrumb></twx-breadcrumb>
      <router-outlet></router-outlet>
    </div>
  </div>
  <!-- <twx-config></twx-config> -->
  <twx-search></twx-search>
  <twx-rightmenu></twx-rightmenu>
  <div class="layout-mask"></div>
</div>
