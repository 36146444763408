import { NgModule } from "@angular/core";
import { Route, RouterModule, Routes } from "@angular/router";
import { AppLayoutComponent } from "./layout/app.layout.component";
import { ifUserAuthenticated } from "./core/auth/utils/if-user-authenticated.guard";
import { routes } from "../environments/environment.routes";

const MAINTENANCE_ROUTES: Route = {
  path: routes.frontend.maintenance,
  children: [
    {
      path: "users",
      loadComponent: () =>
        import(
          "./features/user-management/containers/user-management-container.component"
        ).then((m) => m.UserManagementContainerComponent),
    },
    { path: "", redirectTo: "users", pathMatch: "full" },
  ],
};

const ALERTS_ROUTES: Route = {
  path: routes.frontend.alerts,
  children: [
    {
      path: routes.frontend.mobileNeedMoreProductIssue,
      loadComponent: () =>
        import(
          "./features/alerts/containers/mobile-need-more-product-issue-flow-container/mobile-need-more-product-issue-flow-container.component"
        ).then((m) => m.MobileNeedMoreProductIssueFlowContainerComponent),
    },
    {
      path: routes.frontend.mobileServiceIssue,
      loadComponent: () =>
        import(
          "./features/alerts/containers/mobile-service-issue-flow-container/mobile-service-issue-flow-container.component"
        ).then((m) => m.MobileServiceIssueFlowContainerComponent),
    },
    {
      path: routes.frontend.mobileEquipmentIssue,
      loadComponent: () =>
        import(
          "./features/alerts/containers/mobile-equipment-issue-flow-container/mobile-equipment-issue-flow-container.component"
        ).then((m) => m.MobileEquipmentIssueFlowContainerComponent),
    },
    {
      path: routes.frontend.mobileCreditIssue,
      loadComponent: () =>
        import(
          "./features/alerts/containers/mobile-credit-issue-flow-container/mobile-credit-issue-flow-container.component"
        ).then((m) => m.MobileCreditIssueFlowContainerComponent),
    },
    {
      path: "",
      pathMatch: "full",
      loadComponent: () =>
        import(
          "./features/alerts/containers/alerts-list-container/alerts-list-container.component"
        ).then((m) => m.AlertsListContainerComponent),
    },
  ],
};

const appRoutes: Routes = [
  {
    path: "",
    canActivate: [ifUserAuthenticated],
    component: AppLayoutComponent,
    children: [
      { ...ALERTS_ROUTES },
      { ...MAINTENANCE_ROUTES },
      { path: "", redirectTo: routes.frontend.alerts, pathMatch: "full" },
    ],
  },
  {
    path: routes.frontend.auth,
    data: { breadcrumb: "Auth" },
    loadChildren: () =>
      import("./core/auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: routes.frontend.notfound,
    loadChildren: () =>
      import("./notfound/notfound.module").then((m) => m.NotfoundModule),
  },
  { path: "**", redirectTo: `/${routes.frontend.notfound}` },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
