import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  AfterViewChecked,
} from "@angular/core";
import {
  IsActiveMatchOptions,
  NavigationEnd,
  Params,
  QueryParamsHandling,
  Router,
  UrlTree,
} from "@angular/router";
import {
  animate,
  state,
  style,
  transition,
  trigger,
  AnimationEvent,
} from "@angular/animations";
import { Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { MenuService } from "./app.menu.service";
import { LayoutService } from "./service/app.layout.service";
import { AppSidebarComponent } from "./app.sidebar.component";
import { DomHandler } from "primeng/dom";

export interface MenuItem {
  routerLinkActiveOptions?: { exact: boolean } | IsActiveMatchOptions;
  label?: string;
  icon?: string;
  items?: MenuItem[];
  separator?: boolean;
  labelVisible?: boolean;
  routerLink?: string[] | UrlTree;
  visible?: boolean;
  url?: string;
  target?: string;
  badgeClass?: string;
  badgeValue?: string;
  badge?: string;
  class?: string;
  disabled?: boolean;
  command?: (event: { originalEvent: Event; item: MenuItem }) => void;
  fragment?: string;
  queryParams?: Params | null;
  queryParamsHandling?: QueryParamsHandling;
  preserveFragment?: boolean;
  skipLocationChange?: boolean;
  replaceUrl?: boolean;
  state?: Record<string, unknown>;
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "[twx-menuitem]",
  template: `
    <ng-container>
      <div
        *ngIf="root && item.visible !== false && item.labelVisible"
        class="layout-menuitem-root-text"
      >
        {{ item.label }}
      </div>
      <a
        *ngIf="(!item.routerLink || item.items) && item.visible !== false"
        [attr.href]="item.url"
        (click)="itemClick($event)"
        (mouseenter)="onMouseEnter()"
        [ngClass]="item.class"
        [attr.target]="item.target"
        tabindex="0"
        pRipple
        [pTooltip]="item.label"
        [tooltipDisabled]="!(isSlim && root && !active)"
      >
        <div class="icon-wrapper">
          <i [ngClass]="item.icon" class="layout-menuitem-icon"></i>
        </div>
        <span class="layout-menuitem-text">{{ item.label }}</span>
        <i
          class="pi pi-fw pi-angle-down layout-submenu-toggler"
          *ngIf="item.items"
        ></i>
      </a>
      <a
        *ngIf="item.routerLink && !item.items && item.visible !== false"
        (click)="itemClick($event)"
        (mouseenter)="onMouseEnter()"
        [ngClass]="item.class"
        [routerLink]="item.routerLink"
        routerLinkActive="active-route"
        [routerLinkActiveOptions]="
          item.routerLinkActiveOptions || {
            paths: 'exact',
            queryParams: 'ignored',
            matrixParams: 'ignored',
            fragment: 'ignored',
          }
        "
        [fragment]="item.fragment"
        [queryParamsHandling]="item.queryParamsHandling"
        [preserveFragment]="item.preserveFragment"
        [skipLocationChange]="item.skipLocationChange"
        [replaceUrl]="item.replaceUrl"
        [state]="item.state"
        [queryParams]="item.queryParams"
        [attr.target]="item.target"
        tabindex="0"
        pRipple
        [pTooltip]="item.label"
        [tooltipDisabled]="!(isSlim && root)"
      >
        <i [ngClass]="item.icon" class="layout-menuitem-icon"></i>
        <span class="layout-menuitem-text">{{ item.label }}</span>
        <i
          class="pi pi-fw pi-angle-down layout-submenu-toggler"
          *ngIf="item.items"
        ></i>
      </a>

      <ul
        #submenu
        *ngIf="item.items && item.visible !== false"
        [@children]="submenuAnimation"
        (@children.done)="onSubmenuAnimated($event)"
      >
        <ng-template ngFor let-child let-i="index" [ngForOf]="item.items">
          <li
            twx-menuitem
            [item]="child"
            [index]="i"
            [parentKey]="key"
            [class]="child.badgeClass"
          ></li>
        </ng-template>
      </ul>
    </ng-container>
  `,
  animations: [
    trigger("children", [
      state(
        "collapsed",
        style({
          height: "0",
        }),
      ),
      state(
        "expanded",
        style({
          height: "*",
        }),
      ),
      state(
        "hidden",
        style({
          display: "none",
        }),
      ),
      state(
        "visible",
        style({
          display: "block",
        }),
      ),
      transition(
        "collapsed <=> expanded",
        animate("400ms cubic-bezier(0.86, 0, 0.07, 1)"),
      ),
    ]),
  ],
})
export class AppMenuitemComponent
  implements OnInit, OnDestroy, AfterViewChecked
{
  @Input() item!: MenuItem;

  @Input() index!: number;

  @Input() @HostBinding("class.layout-root-menuitem") root!: boolean;

  @Input() parentKey!: string;

  @ViewChild("submenu") submenu!: ElementRef;

  active = false;

  menuSourceSubscription: Subscription;

  menuResetSubscription: Subscription;

  key = "";

  constructor(
    public layoutService: LayoutService,
    private cd: ChangeDetectorRef,
    private appSidebar: AppSidebarComponent,
    public router: Router,
    private menuService: MenuService,
  ) {
    this.menuSourceSubscription = this.menuService.menuSource$.subscribe(
      (value) => {
        Promise.resolve(null).then(() => {
          if (value.routeEvent) {
            this.active =
              value.key === this.key || value.key.startsWith(this.key + "-")
                ? true
                : false;
          } else {
            if (
              value.key !== this.key &&
              !value.key.startsWith(this.key + "-")
            ) {
              this.active = false;
            }
          }
        });
      },
    );

    this.menuResetSubscription = this.menuService.resetSource$.subscribe(() => {
      this.active = false;
    });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        if (this.isSlim || this.isHorizontal || this.isCompact) {
          this.active = false;
        } else {
          if (this.item?.routerLink) {
            this.updateActiveStateFromRoute();
          }
        }
      });
  }

  ngOnInit() {
    this.key = this.parentKey
      ? this.parentKey + "-" + this.index
      : String(this.index);

    if (
      !(this.isSlim || this.isHorizontal || this.isCompact) &&
      this.item.routerLink
    ) {
      this.updateActiveStateFromRoute();
    }
  }

  ngAfterViewChecked() {
    if (
      this.root &&
      this.active &&
      this.layoutService.isDesktop() &&
      (this.layoutService.isHorizontal() ||
        this.layoutService.isSlim() ||
        this.layoutService.isCompact())
    ) {
      this.calculatePosition(
        this.submenu?.nativeElement,
        this.submenu?.nativeElement.parentElement,
      );
    }
  }

  updateActiveStateFromRoute() {
    const activeRoute = Array.isArray(this.item?.routerLink)
      ? this.router.isActive(
          this.item.routerLink[0],
          (this.item.routerLinkActiveOptions as IsActiveMatchOptions) || {
            paths: "exact",
            queryParams: "ignored",
            matrixParams: "ignored",
            fragment: "ignored",
          },
        )
      : false;

    if (activeRoute) {
      this.menuService.onMenuStateChange({ key: this.key, routeEvent: true });
    }
  }

  onSubmenuAnimated(event: AnimationEvent) {
    if (
      event.toState === "visible" &&
      this.layoutService.isDesktop() &&
      (this.layoutService.isHorizontal() ||
        this.layoutService.isSlim() ||
        this.layoutService.isCompact())
    ) {
      const el = event.element as HTMLUListElement;
      const elParent = el.parentElement as HTMLUListElement;
      this.calculatePosition(el, elParent);
    }
  }

  calculatePosition(overlay: HTMLElement, target: HTMLElement) {
    if (overlay) {
      const { left, top } = target.getBoundingClientRect();
      const [vWidth, vHeight] = [window.innerWidth, window.innerHeight];
      const [oWidth, oHeight] = [overlay.offsetWidth, overlay.offsetHeight];
      const scrollbarWidth = DomHandler.calculateScrollbarWidth();
      // reset
      overlay.style.top = "";
      overlay.style.left = "";

      if (this.layoutService.isHorizontal()) {
        const width = left + oWidth + scrollbarWidth;
        overlay.style.left =
          vWidth < width ? `${left - (width - vWidth)}px` : `${left}px`;
      } else if (
        this.layoutService.isSlim() ||
        this.layoutService.isCompact()
      ) {
        const height = top + oHeight;
        overlay.style.top =
          vHeight < height ? `${top - (height - vHeight)}px` : `${top}px`;
      }
    }
  }

  itemClick(event: Event) {
    // avoid processing disabled items
    if (this.item.disabled) {
      event.preventDefault();
      return;
    }

    // navigate with hover
    if ((this.root && this.isSlim) || this.isHorizontal || this.isCompact) {
      this.layoutService.state.menuHoverActive =
        !this.layoutService.state.menuHoverActive;
    }

    // execute command
    if (this.item.command) {
      this.item.command({ originalEvent: event, item: this.item });
    }

    // toggle active state
    if (this.item.items) {
      this.active = !this.active;

      if (
        this.root &&
        this.active &&
        (this.isSlim || this.isHorizontal || this.isCompact)
      ) {
        this.layoutService.onOverlaySubmenuOpen();
      }
    } else {
      if (this.layoutService.isMobile()) {
        this.layoutService.state.staticMenuMobileActive = false;
      }

      if (this.isSlim || this.isHorizontal || this.isCompact) {
        this.menuService.reset();
        this.layoutService.state.menuHoverActive = false;
      }
    }

    this.menuService.onMenuStateChange({ key: this.key });
  }

  onMouseEnter() {
    // activate item on hover
    if (
      this.root &&
      (this.isSlim || this.isHorizontal || this.isCompact) &&
      this.layoutService.isDesktop()
    ) {
      if (this.layoutService.state.menuHoverActive) {
        this.active = true;
        this.menuService.onMenuStateChange({ key: this.key });
      }
    }
  }

  get submenuAnimation() {
    if (
      this.layoutService.isDesktop() &&
      (this.layoutService.isHorizontal() ||
        this.layoutService.isSlim() ||
        this.layoutService.isCompact())
    )
      return this.active ? "visible" : "hidden";
    else return this.root ? "expanded" : this.active ? "expanded" : "collapsed";
  }

  get isHorizontal() {
    return this.layoutService.isHorizontal();
  }

  get isSlim() {
    return this.layoutService.isSlim();
  }

  get isCompact() {
    return this.layoutService.isCompact();
  }

  @HostBinding("class.active-menuitem")
  get activeClass() {
    return this.active && !this.root;
  }

  ngOnDestroy() {
    if (this.menuSourceSubscription) {
      this.menuSourceSubscription.unsubscribe();
    }

    if (this.menuResetSubscription) {
      this.menuResetSubscription.unsubscribe();
    }
  }
}
