import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Observable } from "rxjs";
// eslint-disable
@Injectable()
export class TenantIdInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const tenantId = "tactiq-35fca"; // TODO: Get tenant ID from subdomain
    const modifiedReq = req.clone({
      setHeaders: { "tenant-id": tenantId },
    });
    return next.handle(modifiedReq);
  }
}
