import { Component, ElementRef, ViewChild } from "@angular/core";
import { AppSidebarComponent } from "./app.sidebar.component";
import { LayoutService } from "./service/app.layout.service";
import { AuthService } from "../core/auth/services/auth.service";
import { Observable } from "rxjs";
import { User } from "../core/auth/utils/user.models";

@Component({
  selector: "twx-topbar",
  templateUrl: "./app.topbar.component.html",
})
export class AppTopbarComponent {
  @ViewChild("menubutton") menuButton!: ElementRef;

  @ViewChild(AppSidebarComponent) appSidebar!: AppSidebarComponent;
  user$: Observable<User | null>;

  constructor(
    public layoutService: LayoutService,
    public el: ElementRef,
    private auth: AuthService,
  ) {
    this.user$ = this.auth.user$;
  }

  onMenuButtonClick() {
    this.layoutService.onMenuToggle();
  }

  onProfileButtonClick() {
    this.layoutService.showRightMenu();
  }

  onSearchClick() {
    this.layoutService.toggleSearchBar();
  }

  onRightMenuClick() {
    this.layoutService.showRightMenu();
  }

  anchor() {
    this.layoutService.state.anchored = !this.layoutService.state.anchored;
  }

  get logo() {
    const logo =
      this.layoutService.config().menuTheme === "white" ||
      this.layoutService.config().menuTheme === "orange"
        ? "dark"
        : "white";
    return logo;
  }
}
