import { Component } from "@angular/core";
import { ConfirmationService } from "primeng/api";
import { DialogService } from "primeng/dynamicdialog";

@Component({
  selector: "twx-root",
  templateUrl: "./app.component.html",
  styleUrl: "./app.component.scss",
  providers: [ConfirmationService, DialogService],
})
export class AppComponent {
  title = "twx-web-client";
}
