<div class="layout-footer">
  <div class="footer-logo-container">
    <img
      [src]="'assets/layout/images/logo-' + logo + '.svg'"
      alt="diamond-layout"
    />
    <span class="footer-app-name">DIAMOND</span>
  </div>
  <span class="footer-copyright">&#169; Your Organization - 2023</span>
</div>
