import { OnInit } from "@angular/core";
import { Component } from "@angular/core";
import { MenuItem } from "./app.menuitem.component";

@Component({
  selector: "twx-menu",
  templateUrl: "./app.menu.component.html",
})
export class AppMenuComponent implements OnInit {
  model: MenuItem[] = [];

  ngOnInit() {
    this.model = [
      {
        label: "Modules",
        labelVisible: false,
        icon: "pi pi-th-large",
        items: [
          {
            label: "Maintenance",
            icon: "pi pi-fw pi-cog",
            items: [
              {
                label: "User Management",
                icon: "pi pi-fw pi-user-edit",
                routerLink: ["/maintenance/users"],
              },
            ],
          },
        ],
      },
    ];
  }
}
