import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AuthService } from "../auth/services/auth.service";
import { routes } from "../../../environments/environment.routes";

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  private auth = inject(AuthService);

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    const userSession = this.auth.userSession.value;
    const accessToken = userSession?.token?.access_token;

    if (accessToken && !PUBLIC_ROUTES.includes(req.url)) {
      req = req.clone({
        setHeaders: { Authorization: `Bearer ${accessToken}` },
      });
    }

    return next.handle(req);
  }
}

const PUBLIC_ROUTES = [routes.api.auth.login, routes.api.auth.verifyEmail];
