<div *twxIsNotMobile class="layout-topbar">
  <div class="topbar-left">
    <!-- <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="cursor-pointer" (click)="anchor();">
            <path d="M15 14.4449V5.55604L10.5556 10.0005L15 14.4449ZM2.22222 20.0005C1.61111 20.0005 1.08796 19.7829 0.652778 19.3477C0.217593 18.9125 0 18.3894 0 17.7783V2.22271C0 1.6116 0.217593 1.08845 0.652778 0.653266C1.08796 0.218081 1.61111 0.000488281 2.22222 0.000488281H17.7778C18.3889 0.000488281 18.912 0.218081 19.3472 0.653266C19.7824 1.08845 20 1.6116 20 2.22271V17.7783C20 18.3894 19.7824 18.9125 19.3472 19.3477C18.912 19.7829 18.3889 20.0005 17.7778 20.0005H2.22222ZM5.55556 17.7783V2.22271H2.22222V17.7783H5.55556ZM7.77778 17.7783H17.7778V2.22271H7.77778V17.7783Z" fill="#1D5163"/>
        </svg> -->

    <a
      #menubutton
      tabindex="0"
      class="menu-button p-trigger"
      (click)="onMenuButtonClick()"
      (keyup.enter)="onMenuButtonClick()"
    >
      <i class="pi pi-chevron-left"></i>
    </a>
    <img
      class="horizontal-logo"
      [routerLink]="['/']"
      [src]="'assets/layout/images/logo-' + logo + '.svg'"
      alt="diamond-layout"
    />
    <span class="topbar-separator"></span>
    <twx-breadcrumb></twx-breadcrumb>
    <img
      class="mobile-logo"
      [src]="'assets/layout/images/logo-' + logo + '.svg'"
      alt="diamond-layout"
    />
  </div>

  <div class="layout-topbar-menu-section">
    <twx-sidebar></twx-sidebar>
  </div>
  <div class="layout-mask modal-in"></div>

  <div class="topbar-right">
    <ul class="topbar-menu">
      <!-- <li class="search-item">
                <a tabindex="0" (click)="onSearchClick()" class="p-trigger">
                    <i class="pi pi-search"></i>
                </a>
            </li> -->
      <!-- <li class="static sm:relative">
                <a tabindex="0" pStyleClass="@next" enterClass="hidden" enterActiveClass="scalein" leaveToClass="hidden" leaveActiveClass="fadeout" [hideOnOutsideClick]="true">
                    <i class="pi pi-bell"></i>
                    <span class="topbar-badge">5</span>
                </a>
                <ul class="list-none p-3 m-0 border-round shadow-2 absolute surface-overlay hidden origin-top w-full sm:w-19rem mt-2 right-0 z-5 top-auto">
                    <li>
                        <a pRipple class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer">
                            <i class="pi pi-shopping-cart mr-3"></i>
                            <span class="flex flex-column">
                                <span class="font-semibold">New Order</span>
                                <span class="text-color-secondary">You have <strong>3</strong> new orders.</span>
                            </span>
                        </a>
                        <a pRipple class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer">
                            <i class="pi pi-check-square mr-3"></i>
                            <span class="flex flex-column">
                                <span class="font-semibold">Withdrawn Completed</span>
                                <span class="text-color-secondary">Funds are on their way.</span>
                            </span>
                        </a>
                        <a pRipple class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer">
                            <i class="pi pi-chart-line mr-3"></i>
                            <span class="flex flex-column">
                                <span class="font-semibold">Monthly Reports</span>
                                <span class="text-color-secondary">Monthly Reports are ready.</span>
                            </span>
                        </a>
                        <a pRipple class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer">
                            <i class="pi pi-comments mr-3"></i>
                            <span class="flex flex-column">
                                <span class="font-semibold">Comments</span>
                                <span class="text-color-secondary"><strong>2</strong> new comments.</span>
                            </span>
                        </a>
                        <a pRipple class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer">
                            <i class="pi pi-exclamation-circle mr-3"></i>
                            <span class="flex flex-column">
                                <span class="font-semibold">Chargeback Request</span>
                                <span class="text-color-secondary"><strong>1</strong> to review.</span>
                            </span>
                        </a>
                    </li>
                </ul>
            </li> -->

      <li class="profile-item static sm:relative" *ngIf="user$ | async as user">
        <a>
          <i class="pi pi-user z-1 text-base mr-3 pl-2"></i>

          <div class="profile-name">
            <p class="profile-name__email m-0">{{ user?.email }}</p>
            <p class="profile-name__type text-left text-xs font-normal">
              {{ user?.claims?.type | titlecase }}
            </p>
          </div>
        </a>
        <ul
          class="list-none p-3 m-0 border-round shadow-2 absolute surface-overlay hidden origin-top w-full sm:w-12rem mt-2 right-0 z-5 top-auto"
        >
          <li>
            <a
              pRipple
              class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer"
            >
              <i class="pi pi-user mr-3"></i>
              <span class="flex flex-column">
                <span class="font-semibold">Profile</span>
              </span>
            </a>
            <a
              pRipple
              class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer"
            >
              <i class="pi pi-cog mr-3"></i>
              <span class="flex flex-column">
                <span class="font-semibold">Settings</span>
              </span>
            </a>
            <a
              pRipple
              class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer"
            >
              <i class="pi pi-calendar mr-3"></i>
              <span class="flex flex-column">
                <span class="font-semibold">Calendar</span>
              </span>
            </a>
            <a
              pRipple
              class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer"
            >
              <i class="pi pi-inbox mr-3"></i>
              <span class="flex flex-column">
                <span class="font-semibold">Inbox</span>
              </span>
            </a>
            <a
              pRipple
              class="flex p-2 border-round align-items-center hover:surface-hover transition-colors transition-duration-150 cursor-pointer"
            >
              <i class="pi pi-power-off mr-3"></i>
              <span class="flex flex-column">
                <span class="font-semibold">Logout</span>
              </span>
            </a>
          </li>
        </ul>
      </li>
      <!-- <li class="right-sidebar-item">
                <a tabindex="0" (click)="onRightMenuClick()">
                    <i class="pi pi-align-right"></i>
                </a>
            </li> -->
    </ul>
  </div>
</div>

<div
  *twxIsMobile
  class="mobile-layout-topbar flex align-items-center justify-content-between"
>
  <twx-retailer-sidebar-card></twx-retailer-sidebar-card>

  <a [routerLink]="['/']" class="logo">
    <div class="logo-image w-full flex justify-content-center">
      <img
        [src]="theme.configValue.mainLogoContrastUrl"
        class="tactiq-logo"
        alt="Tactiq logo"
      />
    </div>
  </a>

  <i class="pi pi-cog settings"></i>
</div>
