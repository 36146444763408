import { Component, inject } from "@angular/core";
import { routes } from "../../environments/environment.routes";
import { AlertsService } from "../features/alerts/services/alerts.service";

@Component({
  selector: "twx-bottombar",
  templateUrl: "./app.bottombar.component.html",
  styleUrl: "./app.bottombar.component.scss",
})
export class BottombarComponent {
  readonly alertsRoute = routes.frontend.alerts;

  private alertsService = inject(AlertsService);

  openMobileAlertCreationSelection(): void {
    this.alertsService.openAlertTypeMobileSelectionSidebar();
  }
}
